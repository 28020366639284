<template>
  <div>
    <a-card title="开发详情">
      <a-button
        slot="extra"
        type="primary"
        style="margin-right: 8px"
        ghost
        v-print="'#printContent'"
      >
        <a-icon type="printer" />打印</a-button
      >
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >

      <section id="printContent">
        <a-spin :spinning="loading">
          <a-descriptions bordered>
            <a-descriptions-item label="cs_id">
              {{ info.cs_id }}
            </a-descriptions-item>
            <a-descriptions-item label="状态">
              {{ statusMap[info.status] }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.update_user_name }}
            </a-descriptions-item>
            <a-descriptions-item label="提交用户">
              {{ info.bm_user_name }}
            </a-descriptions-item>
            <a-descriptions-item label="提交日期">
              {{ info.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="更新日期">
              {{ info.update_time }}
            </a-descriptions-item>
            <a-descriptions-item label="用户备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>

          <a-divider orientation="left" style="margin-top: 30px"
            >产品信息</a-divider
          >
          <div>
            <a-row>
              <a-space>
                <a-button
                  type="primary"
                  @click="openGoodModal"
                  :disabled="add_button"
                  >添加产品</a-button
                >
              </a-space>
            </a-row>
            <div style="margin-top: 10px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="goods_columns"
                :data-source="this.goodItems"
                :pagination="false"
              >
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group
                    size="small"
                    v-if="pending_status.includes(status)"
                  >
                    <a-button type="danger" @click="removeGood()"
                      >移除</a-button
                    >
                  </a-button-group>
                </div>
              </a-table>
            </div>
          </div>

          <a-divider orientation="left" style="margin-top: 30px"
            >物料信息</a-divider
          >
          <div style="margin-top: 5px">
            <a-row gutter="16">
              <a-space>
                <!-- 添加物料 -->
                <a-button
                  type="primary"
                  @click="openMaterialModal"
                  :disabled="goodItems.length == 0 || add_button"
                  >添加物料</a-button
                >
              </a-space>
            </a-row>
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="materials_columns"
                :data-source="materialsData"
                :pagination="false"
              >
                <div slot="purchase_quantity" slot-scope="value, item, index">
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number
                    v-else
                    v-model="item.purchase_quantity"
                    :min="0"
                    size="small"
                    :disabled="add_button"
                  ></a-input-number>
                </div>
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group
                    v-if="pending_status.includes(status) && !item.isTotal"
                    size="small"
                  >
                    <a-button type="danger" @click="removeMaterial(item)"
                      >移除</a-button
                    >
                  </a-button-group>
                </div>
              </a-table>
            </div>
          </div>
        </a-spin>
      </section>

      <!-- <div style="margin-top: 32px">
        <a-popconfirm title="确定更新吗?" @confirm="update_goods_materials">
          <a-button type="primary" :loading="loading_re" :disabled="goodItems.length==0">保存物料与产品信息</a-button>
        </a-popconfirm>
      </div> -->

      <a-divider orientation="left">状态信息</a-divider>
      <div>
        <a-row gutter="16">
          <a-col :span="4">
            <a-form-model-item
              label="开发状态"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="status"
                style="width: 100%"
                @change="checkStatusChange"
              >
                <a-select-option
                  v-for="(label, key) in statusMap"
                  :key="key"
                  :value="key"
                >
                  {{ label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="备注"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input v-model="bm_remark" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确保保存吗?" @confirm="update">
          <a-button type="primary" :loading="loading">提交</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <goods-select-modal
      v-model="goodsSelectModalVisible"
      @select="onSelectGood"
    ></goods-select-modal>
    <materials-select-modal
      v-model="materialsSelectModalVisible"
      :warehouse="1"
      @select="onSelectMaterial"
    ></materials-select-modal>
  </div>
</template>

<script>
import { developGoodsDetail,developGoodsUpdate } from "@/api/goods";
import { GoodMaterialsRe, UpdateGoodMaterialsRe } from "@/api/production";
import { inventoriemsOption, goodsOption } from "@/api/option";

import NP from "number-precision";

export default {
  components: {
    GoodsSelectModal: () => import("@/components/GoodsSelectModal/index"),
    MaterialsSelectModal: () =>
      import("@/components/MaterialSelectModal/index"),
  },

  data() {
    return {
      loading: false,
      status: "",
      statusMap: {
        concept: "待开发",
        in_development: "开发中",
        pending_review: "等待审核",
        launched: "已发布",
        discontinued: "停产",
        archived: "已归档",
      },
      bm_remark: "",
      pending_status: ["concept", "in_development", "pending_review"],

      info: {},
      goods_columns: [
        {
          title: "产品名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],

      materials_columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "所需数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
          width: 120,
          scopedSlots: { customRender: "purchase_quantity" },
        },
        {
          title: "采购单价(元)",
          dataIndex: "purchase_price",
          key: "purchase_price",
          width: 120,
        },
        {
          title: "仓库库存",
          dataIndex: "materials_inventory",
          key: "materials_inventory",
          width: 80,
        },

        {
          title: "金额",
          dataIndex: "OneAmount",
          key: "OneAmount",
          width: 80,
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(item.purchase_quantity, item.purchase_price);
            return item.id ? NP.round(value, 2) : "";
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],

      materialItems: [],
      goodItems: [],
      id: this.$route.query.id,
      goodsSelectModalVisible: false,
      materialsSelectModalVisible: false,
    };
  },

  computed: {
    add_button() {
      const is_pending_status = !this.pending_status.includes(this.status);
      return is_pending_status;
    },

    materialsData() {
      let totalQuantity = 0,
        one_amount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.purchase_quantity);
        let amount_cost = NP.times(item.purchase_quantity, item.purchase_price);
        one_amount = NP.plus(one_amount, amount_cost);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          purchase_quantity: totalQuantity,
          OneAmount: one_amount,
        },
      ];
    },
  },
  methods: {
    async initData() {
      await this.get_dev_goods_info();
    },

    match_product(product_name) {
      // 根据cs匹配bm的产品
      if (!product_name) {
        console.log("没有提供名称,无法进行匹配");
      }
      console.log("匹配产品名称", product_name);
      goodsOption({ cs_id: product_name }).then((data) => {
        if (data.results.length == 0) {
          this.$message.info("没匹配到BM产品");
          this.goodItems = [];
        } else {
          var item = data.results[0];
          this.goodItems = [
            {
              id: item.id,
              goods: item.goods,
              goods_number: item.number,
              goods_name: item.name,
              retail_price: item.retail_price,
              level_price1: item.level_price1,
              level_price2: item.level_price2,
              level_price3: item.level_price3,
            },
          ];
          // this.sale_price = item.retail_price
          this.$message.success(`匹配到BM产品${item.name}`);
          this.getMaterialItem(item.id);
        }
      });
    },

    async get_dev_goods_info() {
      this.loading = true;
      await developGoodsDetail(this.$route.query)
        .then((data) => {
          this.info = data;
          // 单独赋值
          this.status = data.status;
          console.log("status", this.status);
          this.currentStatus = data.status;
          this.bm_remark = data.bm_remark;
          if (data.goods === null) {
            console.log("Goods field is null");
            this.match_product(data.cs_id);
            // this.goodItems = [];  // 可能需要设置为一个空数组或其他默认值
          } else {
            this.goodItems = [
              {
                id: data.goods,
                goods_name: data.bm_goods_name,
                goods_number: data.goods_number,
                retail_price: data.retail_price,
                level_price1: data.level_price1,
                level_price2: data.level_price2,
                level_price3: data.level_price3,
              },
            ];
            this.getMaterialItem(data.goods);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getMaterialItem(id) {
      this.loading = true;
      try {
        // 第一步: 获取材料信息
        const materialResponse = await GoodMaterialsRe({ goods: id });
        if (materialResponse.results.length === 0) {
          console.log("No materials found for the given ID.");
          this.materialItems = [];
          return;
        }
        const material_goods_quantity = materialResponse.results;
        // 第二步: 使用第一步的结果请求库存信息
        const material_ids = materialResponse.results
          .map((item) => item.material)
          .join(",");
        const inventoryResponse = await inventoriemsOption({
          materials__in: material_ids,
          warehouse: 1,
        });

        // 第三步: 处理并保存库存信息
        this.materialItems = inventoryResponse.results.map((item) => ({
          // Mapping your data structure here
          id: item.id,
          materials: item.materials,
          materials_number: item.materials_number,
          materials_name: item.materials_name,
          materials_spec: item.materials_spec,
          purchase_price: item.purchase_price,
          materials_inventory: item.total_quantity,
          purchase_quantity: material_goods_quantity.find(
            (goods) => goods.material === item.materials
          ).material_quantity,
          total_quantity: item.total_quantity,
          unit_name: item.unit_name,
        }));
      } catch (error) {
        console.error("Error fetching materials data:", error);
      } finally {
        this.loading = false;
        this.material_button = false;
      }
    },

    openGoodModal() {
      this.goodsSelectModalVisible = true;
    },

    // 添加物料
    openMaterialModal() {
      this.materialsSelectModalVisible = true;
    },

    onSelectGood(item) {
      // console.log(item)

      if (this.goodItems.length > 0 && this.goodItems[0].id === item.id) {
        this.$message.warn("产品已存在");
        return;
      }
      this.goodItems = [
        {
          id: item.id,
          goods: item.goods,
          goods_number: item.number,
          goods_name: item.name,
          retail_price: item.retail_price,
          level_price1: item.level_price1,
          level_price2: item.level_price2,
          level_price3: item.level_price3,
        },
      ];
      // this.sale_price = item.retail_price
      //  进行物料的获取
      this.material_button = true;
      this.getMaterialItem(item.id);
    },

    onSelectMaterial(item) {
      let index = this.materialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn("物料已存在");
        return;
      }
      this.materialItems = this.$functions.insertItem(this.materialItems, {
        id: item.id,
        materials: item.materials,
        materials_number: item.materials_number,
        materials_name: item.materials_name,
        materials_spec: item.materials_spec,
        unit_name: item.unit_name,
        purchase_quantity: 1,
        purchase_price: item.purchase_price,
        materials_inventory: item.total_quantity,
      });
    },

    removeGood() {
      this.goodItems = [];
      this.materialItems = [];
    },
    removeMaterial(item) {
      this.materialItems = this.$functions.removeItem(this.materialItems, item);
    },

    update_goods_materials() {
      this.loading_re = true;
      let formGoodsMaterialsData = {
        id: this.info.id,
        statues: this.statues,
        goods:
          this.goodItems && this.goodItems.length > 0
            ? this.goodItems[0].id
            : null,
        materials_items: this.materialItems.map((item) => {
          return {
            materials: item.materials,
            purchase_quantity: item.purchase_quantity,
          };
        }),
      };
      UpdateGoodMaterialsRe(formGoodsMaterialsData)
        .then((data) => {
          this.$message.success("更新产品与物料成功");
          // this.$router.push({ path: "/home" });
          // this.$router.push({ path: "/purchasing/purchase_record" });
        })
        .finally(() => {
          this.loading_re = false;
        });
    },
    update_develop(){

      let formData = {
        id: this.info.id,
        status: this.status,
        goods: this.goodItems && this.goodItems.length > 0 ? this.goodItems[0].id : null,
        bm_remark: this.bm_remark,
      };

      developGoodsUpdate(formData).then((data) => {
        this.currentStatus = data.statues
        this.$message.success("更新成功");
      }).catch((error) => {
          // 处理错误的逻辑
          //  检查错误对象并提取服务器返回的错误信息
          const errorMessage = error.response && error.response.data
              ? error.response.data
              : "未知错误"; // 提供一个默认错误消息
          this.$message.error("更新失败: " + errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },


    update() {
      this.update_goods_materials()
      this.update_develop()

    },
  },

  mounted() {
    console.log("Hello");
    this.initData();
  },
};
</script>


<style></style>